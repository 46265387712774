<template>
<div class="config-device-container">
  <div class="config-tree-area">
    <GroupTree />
  </div>
  <div class="device-data-area">
    <div class="query-data-area">
      <span class="ml10">{{showLang('com.tab.title')}}：</span>
      <Input type="text" v-model="filter.name" clearable :placeholder="showLang('com.tab.title')"
        style="width: 140px; margin-right: 10px;"></Input>
      {{showLang('com.export.cn.connid')}}：
      <Input type="text" v-model="filter.code" clearable :placeholder="showLang('com.export.cn.connid')"
        style="width: 140px; margin-right: 10px;"></Input>
      所在分组：
      <Select :placeholder="showLang('save.select')" v-model="filter.lightGroupId" style="width: 120px;margin-right: 10px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <template v-for="(s, idx) in args.groups">
          <Option :value="s.id" :key="idx">{{s.name}}[{{s.code}}]</Option>
        </template>
      </Select>
      应用类型：
      <Select :placeholder="showLang('save.select')" v-model="filter.useType" style="width: 120px;margin-right: 10px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <template v-for="(item, idx) in args.useTypes">
          <Option :value="item.id" :key="idx">{{item.name}}</Option>
        </template>
      </Select>
      计时日表:
      <Select :placeholder="showLang('save.select')" v-model="filter.planId1" style="width: 120px;margin-right: 10px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <template v-for="(s, idx) in args.plan1s">
          <Option :value="s.id" :key="idx">{{s.name}}</Option>
        </template>
      </Select>
      准时日表:
      <Select :placeholder="showLang('save.select')" v-model="filter.planId2" style="width: 120px;margin-right: 10px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <template v-for="(s, idx) in args.plan2s">
          <Option :value="s.id" :key="idx">{{s.name}}</Option>
        </template>
      </Select>
      控制方式:
      <Select :placeholder="showLang('save.select')" v-model="filter.mode" style="width: 120px;margin-right: 10px;">
        <Option :value="0">{{showLang('com.state.all')}}</Option>
        <template v-for="(s, idx) in args.modes">
          <Option :value="s.id" :key="idx">{{s.name}}</Option>
        </template>
      </Select>
      <Button v-if="funCodes(2070)" size="default" type="primary" @click="search" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
    </div>
    <div class="query-data-area">
      <Button v-if="funCodes(2071)" size="default" type="info" @click="getImportTemplate" style="margin: 0 5px;">灯控导入模板</Button>
      <Button v-if="funCodes(2071)" size="default" type="success" @click="importLampNew" style="margin: 0 5px;">{{showLang('import.batch')}}</Button>
      <Button type="success" :loading="exportLoading" @click="exportData" style="margin-right: 10px;">{{showLang('com.op.export')}}</Button>
      <template v-for="(btn, idx) in btns">
        <Button v-if="funCodes(2072)" size="default" :key="idx" type="primary" @click="docmd(btn)" style="margin: 2px 5px;">{{btn.name}}</Button>
        <!-- showLang(btn.lang) -->
      </template>
      <!-- <Dropdown>
        <Button type="primary">
          {{showLang('com.data.re.energy.count')}}
          <Icon type="ios-arrow-down"></Icon>
        </Button>
        <template #list>
          <Form :model="formRepair" :label-width="120" style="margin-right: 30px; margin-top: 30px;">
            <FormItem label="修复起始日期">
              <DatePicker type="date" :placeholder="showLang('filter.time.start')" v-model="formRepair.start"></DatePicker>
            </FormItem>
            <FormItem label="修复截止日期">
              <DatePicker type="date" :placeholder="showLang('filter.time.end')" v-model="formRepair.end"></DatePicker>
            </FormItem>
            <FormItem :label-width="0">
              <Button v-if="funCodes('la')" size="default" type="primary" @click="repairStationEnergy" style="margin-left: 100px">{{showLang('com.data.re.energy.count')}}</Button>
            </FormItem>
          </Form>
        </template>
      </Dropdown> -->
    </div>
    <div class="alarm-data-area" ref="table1">
      <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source
        ref="chkTable" :key="dataRefresh" @checkbox-change="selectChangeEvent" :data="list" height="auto" stripe
        :row-config="{isHover: true, height: rowHeight}" :checkbox-config="{checkField: 'checked',}" @checkbox-all="selectAllEvent"
        :seq-config="{startIndex: (filter.index-1)*filter.size}" row-id="id">
        <vxe-column type="seq" width="60" :title="showLang('com.export.cn.seq')" fixed="left"></vxe-column>
        <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
        <vxe-column field="name" :title="showLang('com.tab.title')" width="150" fixed="left" header-align="center"></vxe-column>
        <vxe-column field="code" :title="showLang('com.export.cn.connid')" width="150" header-align="center"></vxe-column>
        <vxe-column field="groupName" title="所在分区" width="150" header-align="center"></vxe-column>
        <vxe-column field="lightGroupName" title="所在分组" width="150" header-align="center"></vxe-column>
        <vxe-column field="useTypeName" title="应用类型" width="150" header-align="center"></vxe-column>
        <vxe-column field="deviceTypeName" title="设备类型" width="250" header-align="center"></vxe-column>
        <vxe-column field="gatewayName" title="关联网关" width="150" header-align="center"></vxe-column>
        <vxe-column field="channel" title="上行通道号" width="100" header-align="center"></vxe-column>
        <!-- <vxe-colgroup title="报警参数" header-align="center"> -->
          <vxe-colgroup title="电压报警" header-align="center">
            <vxe-column field="voltageAlarm" :title="showLang('alarm.enable')" header-align="center" width="90">
              <template #default="{ row }">
                {{row.voltageAlarm ? showLang('com.state.enable') : showLang('com.state.disable')}}
              </template>
            </vxe-column>
            <vxe-column field="voltageLower" :title="showLang('com.data.lower.val')" header-align="center" width="90"></vxe-column>
            <vxe-column field="voltageUpper" :title="showLang('com.data.upper.val')" header-align="center" width="90"></vxe-column>
          </vxe-colgroup>
          <vxe-colgroup title="温度报警" header-align="center">
            <vxe-column field="tempAlarm" :title="showLang('alarm.enable')" header-align="center" width="90">
              <template #default="{ row }">
                {{row.tempAlarm ? showLang('com.state.enable') : showLang('com.state.disable')}}
              </template>
            </vxe-column>
            <vxe-column field="tempLevel1" title="一级阈值" header-align="center" width="90"></vxe-column>
            <vxe-column field="tempLevel2" title="二级阈值" header-align="center" width="90"></vxe-column>
          </vxe-colgroup>
          <vxe-column field="gyroAlarm" :title="showLang('alarm.type.gyro')" header-align="center" width="90">
            <template #default="{ row }">
              {{row.gyroAlarm ? showLang('com.state.enable') : showLang('com.state.disable')}}
            </template>
          </vxe-column>
        <!-- </vxe-colgroup> -->
        <!-- <vxe-colgroup :title="showLang('com.ope.nav.channel.1')" header-align="center"> -->
          <vxe-colgroup title="控制参数" header-align="center">
            <vxe-column title="通道号" width="70" header-align="center">
              <template>
                <div class="split-row-area">
                  <div class="split-row-item split-row-flag">通道一</div>
                  <div class="split-row-item">通道二</div>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="enable1" :title="showLang('com.state.enable')" header-align="center" width="60">
              <template #default="params">
                <div class="split-row-area">
                  <div class="split-row-item split-row-flag">{{params.row.enable1 ? showLang('com.state.enable') : showLang('com.state.disable')}}</div>
                  <div class="split-row-item">{{params.row.enable2 ? showLang('com.state.enable') : showLang('com.state.disable')}}</div>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="after1" :title="showLang('com.lighting.pr1')+'(W)'" header-align="center" width="120">
              <template #default="params">
                <div class="split-row-area">
                  <div  class="split-row-item split-row-flag">{{params.row.after1}}</div>
                  <div class="split-row-item">{{params.row.after2}}</div>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="before1" :title="showLang('com.import.cn.lamp.p')+'(W)'" header-align="center" width="120">
              <template #default="params">
                <div class="split-row-area">
                  <div class="split-row-item split-row-flag">{{params.row.before1}}</div>
                  <div class="split-row-item">{{params.row.before2}}</div>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="count1" :title="showLang('com.import.cn.lamp.num')" header-align="center" width="100">
              <template #default="params">
                <div class="split-row-area">
                  <div class="split-row-item split-row-flag">{{params.row.count1}}</div>
                  <div class="split-row-item">{{params.row.count2}}</div>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="adjust1" title="监测系数" header-align="center" width="100">
              <template #default="params">
                <div class="split-row-area">
                  <div class="split-row-item split-row-flag">{{params.row.adjust1}}</div>
                  <div class="split-row-item">{{params.row.adjust2}}</div>
                </div>
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup title="控制方式" header-align="center">
            <vxe-column field="modeName1" title="控制方式" header-align="center" width="100">
              <template #default="params">
                <div class="split-row-area">
                  <div class="split-row-item split-row-flag">{{params.row.modeName1}}</div>
                  <div class="split-row-item">{{params.row.modeName2}}</div>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="planName11" title="关联计时日表" header-align="center" width="150">
              <template #default="params">
                <div class="split-row-area">
                  <div class="split-row-item split-row-flag">{{params.row.planName11}}</div>
                  <div class="split-row-item">{{params.row.planName12}}</div>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="planName21" title="关联准时日表" header-align="center" width="150">
              <template #default="params">
                <div class="split-row-area">
                  <div class="split-row-item split-row-flag">{{params.row.planName21}}</div>
                  <div class="split-row-item">{{params.row.planName22}}</div>
                </div>
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup title="电流报警" header-align="center">
            <vxe-column field="currentAlarm1" :title="showLang('com.state.enable')" header-align="center" width="60">
              <template #default="params">
                <div class="split-row-area">
                  <div class="split-row-item split-row-flag">{{params.row.currentAlarm1? showLang('com.state.enable') : showLang('com.state.disable')}}</div>
                  <div class="split-row-item">{{params.row.currentAlarm2? showLang('com.state.enable') : showLang('com.state.disable')}}</div>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="currentLower1" title="下限值(A)" header-align="center" width="100">
              <template #default="params">
                <div class="split-row-area">
                  <div class="split-row-item split-row-flag">{{params.row.currentLower1}}</div>
                  <div class="split-row-item">{{params.row.currentLower2}}</div>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="currentUpper1" title="上限值(A)" header-align="center" width="100">
              <template #default="params">
                <div class="split-row-area">
                  <div class="split-row-item split-row-flag">{{params.row.currentUpper1}}</div>
                  <div class="split-row-item">{{params.row.currentUpper2}}</div>
                </div>
              </template>
            </vxe-column>
          </vxe-colgroup>
          <vxe-colgroup title="漏电报警" header-align="center">
            <vxe-column field="leakageCurrentAlarm1" title="漏电电流" header-align="center" width="100">
              <template #default="params">
                <div class="split-row-area">
                  <div class="split-row-item split-row-flag">{{params.row.leakageCurrentAlarm1 ? showLang('com.state.enable') : showLang('com.state.disable')}}</div>
                  <div class="split-row-item">{{params.row.leakageCurrentAlarm2 ? showLang('com.state.enable') : showLang('com.state.disable')}}</div>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="leakageCurrentUpper1" title="电流阈值(mA)" header-align="center" width="120">
              <template #default="params">
                <div class="split-row-area">
                  <div class="split-row-item split-row-flag">{{params.row.leakageCurrentUpper1}}</div>
                  <div class="split-row-item">{{params.row.leakageCurrentUpper2}}</div>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="leakageVoltageAlarm1" title="漏电电压" header-align="center" width="100">
              <template #default="params">
                <div class="split-row-area">
                  <div class="split-row-item split-row-flag">{{params.row.leakageVoltageAlarm1 ? showLang('com.state.enable') : showLang('com.state.disable')}}</div>
                  <div class="split-row-item">{{params.row.leakageVoltageAlarm2 ? showLang('com.state.enable') : showLang('com.state.disable')}}</div>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="leakageVoltageUpper1" title="电压阈值(V)" header-align="center" width="100">
              <template #default="params">
                <div class="split-row-area">
                  <div class="split-row-item split-row-flag">{{params.row.leakageVoltageUpper1}}</div>
                  <div class="split-row-item">{{params.row.leakageVoltageUpper2}}</div>
                </div>
              </template>
            </vxe-column>
          </vxe-colgroup>
        <vxe-column width="160" fixed="right">
          <template #header>
            <Button v-if='funCodes(2071)' size="small" type="primary" style="margin-right: 5px" @click="handleAdd">{{showLang('com.op.add')}}</Button>
            <!-- <Button v-if='funCodes(2070)' size="small" type="primary" style="margin-right: 5px" @click="getList">{{showLang('com.op.refresh')}}</Button> -->
            <Button v-if="funCodes(2073)" size="small" type="error" @click="deleteParams" style="margin: 0 5px;">{{showLang('com.op.batch.del')}}</Button>
          </template>
          <template #default="params">
            <Button v-if='funCodes(2072)' size="small" type="primary" style="margin-right: 5px" @click="handleEdit(params)">{{showLang('com.op.edit')}}</Button>
            <Button v-if='funCodes(2073)' size="small" type="error" style="margin-right: 5px" @click="handleDelete(params)">{{showLang('com.op.del')}}</Button>
          </template>
        </vxe-column>
      </vxe-table>
      <ModalLampEdit v-model="showEditModal" :item="editItem" :args="args" @saved="itemSaved" />
      <ModalLampImportNew v-model="showImportModalNew" :item="editItem" :args="args" @saved="itemSaved" />
      <ModalEditArgs v-model="showArgModal" :item="editItem" :args="args" @saved="argsSaved" />
    </div>
    <div class="footer-data-area">
      <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
    </div>
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalLampEdit from './ModalLampEdit'
import ModalLampImportNew from './ModalLampImportNew'
import ModalEditArgs from './ModalEditArgs'
import GroupTree from '../../../common/treeGroup/Index'
export default {
  name: 'ConfigLampIndex',
  components: {
    ModalLampEdit,
    ModalLampImportNew,
    ModalEditArgs,
    GroupTree,
  },
  props: {
  },
  data() {
    return {
      curTab: 'hour24',
      list: [],
      tabHeight: 600,
      rowHeight: 60,
      editItem: { isAdd: true, data: {} },
      showEditModal: false,
      showArgModal: false,
      showImportModalNew: false,
      showImportModalOld: false,
      exportLoading: false,
      dataRefresh: 0,
      filter: {
        groupId: 0,
        gatewayId: '',
        name: "",
        code: "",
        planId1: 0,
        planId2: 0,
        lightGroupId: 0,
        mode: 0,
        useType: 0,
        size: 20,//20 100 500 1000 5000
        index: 1,
      },
      total: 0,
      exportExcel: {
        data: [],
        isAll: false,
      },
      form: {
        name: '',
        areaId: 0,
      },
      args: {
        plan1s: [],
        plan2s: [],
        useTypes: [],
        modes: [],
        gateways: [],
        deviceTypes: [],
        groups: [],
      },
      btns: [
        { code: 'updateGroup', name: '设定分区',lang:'com.ope.association.ch', width: 500 },
        { code: 'updateUseType', name: '设定类型',lang:'com.ins.set.updateLightType', width: 500 },
        { code: 'updateLightGroup', name: '设定灯控分组',lang:'com.ins.set.updateLightGroup', width: 500 },
        { code: 'updateConnection', name: '设定连接方式',lang:'com.ope.association.ch', width: 1000 },
        { code: 'updateVoltageAlarm', name: '设定电压报警',lang:'com.ins.set.updateVoltageRate', width: 800 },
        { code: 'updateTempAlarm', name: '设定温度报警',lang:'com.ins.set.updateTempRate', width: 800 },
        { code: 'updateChannel', name: '设定控制参数',lang:'com.ins.set.updateControlChannel', width: 1200 },
        { code: 'updatePlanMode', name: '设定控制方式',lang:'com.ins.set.updateLightTimeTable', width: 1200 },
        { code: 'updateCurrentAlarm', name: '设定电流报警',lang:'com.ins.set.updateCurrentRate', width: 800 },
        { code: 'updateLeakageAlarm', name: '设定漏电报警',lang:'com.ins.set.updateLeakageRate', width: 1200 },
      ],
      formRepair: {
        start: '',
        end: '',
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['pageLayouts', 'pageSizes']),
    ...mapState('group', ['groups', 'groupTreeSelectedNode']),
  },
  watch: {
    // windowOnResize() {
    //   this.setTableHeight();
    // },
    groupTreeSelectedNode(){
      this.search();
    },
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 100);
    let now = new Date();
    let monthFirst = now.monthFirstDay();
    monthFirst.setMonth(monthFirst.getMonth() - 1);
    this.formRepair.start = monthFirst.format('yyyy-MM-dd');
    let monthLast = monthFirst.monthLastDay();
    this.formRepair.end = monthLast.format('yyyy-MM-dd');
    this.queryLightGroups();
    this.getLampUseTypes();
    this.getLampControlModes();
    this.queryLampTypes();
    this.queryGateway();
    this.queryPlan1List();
    this.queryPlan2List();
  },
  destroyed: function () {
  },
  methods: {
    search: function(){
      this.filter.index=1;
      this.getList();
    },
    queryLightGroups: function () {
      this.$axios.post(`device/light/QueryGroup`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.args, 'groups', res.data);
        }
      });
    },
    getLampUseTypes: function () {
      this.$axios.post(`device/light/GetLampUseTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.args, 'useTypes', res.data);
        }
      });
    },
    getLampControlModes: function () {
      this.$axios.post(`device/light/GetLampControlModes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.args, 'modes', res.data);
        }
      });
    },
    queryLampTypes: function () {
      this.$axios.post(`device/light/QueryLampTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.args, 'deviceTypes', res.data);
        }
      });
    },
    queryGateway: function () {
      this.$axios.post(`device/light/QueryGatewayConfig`, {index: 1, size: 5000}).then(res => {
        if (res.code == 0) {
          this.$set(this.args, 'gateways', res.data.list);
        }
      });
    },
    queryPlan1List: function () {
      this.$axios.post(`device/light/QueryPlan1List`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.args, 'plan1s', res.data);
        }
      });
    },
    queryPlan2List: function () {
      this.$axios.post(`device/light/QueryPlan2List`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.args, 'plan2s', res.data);
        }
      });
    },
    getImportTemplate: function(){
      this.$store.dispatch('auth/reqFile', {
        title: '单灯导入模板.xlsx',
        url: `device/light/GetImportLightTemplate`,
        args: {},
      });
    },
    getDeviceName: function (item) {
      return `${item.name}[${this.deviceTypes[item.type]}][ID:${item.code}]`
    },
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList(true)
    },
    selectAllEvent({ checked }) {//全选
      this.exportExcel.isAll = checked;
      if (!checked) {
        this.exportExcel.data = [];
      }
    },
    checkRowKeys() {//默认选中
      let table = this.$refs.chkTable;
      this.exportExcel.data.forEach(el => {
        this.list.forEach((ele) => {
          if (ele.id == el.id) {
            table.setCheckboxRow(table.getRowById(ele.id), true);
          }
        });
      })
      this.dataRefresh++;
    },
    exportData: function () {
      this.filter.groupId = this.groupTreeSelectedNode.id;
      this.$store.dispatch('auth/reqFile', {
        title: '单灯配置数据.xlsx',
        url: `device/light/ExportNonePoleLightConfigs`,
        args: this.filter,
      });
    },
    docmd: function (params) {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('save.batch.edited.cmd.record'));
        return;
      }
      this.editItem = params;
      this.showArgModal = true;
    },
    argsSaved: function (params) {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('save.batch.edited.cmd.record'));
        return;
      }
      let list = chks.map(p => p.id);
      // console.log('batch save light', list, params);
      // return;
      this.$axios.post(`device/light/BatchEditLight`, {
        list, cmd: params.code, args: params.args
      }).then(res => {
        if (res.code == 0) {
          this.getList();
        }
      });
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex);
      let leng = this.exportExcel.data.filter((el) => el.id == this.list[rowIndex].id)
      if (leng.length == 0 && this.list[rowIndex].checked) {
        this.exportExcel.data.push(this.list[rowIndex])
      }
      if(!this.list[rowIndex].checked && leng.length >0){
        this.exportExcel.data= this.exportExcel.data.filter(item=>item.id!==this.list[rowIndex].id)
      }
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 50;
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, data: {} };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params.row };
      this.showEditModal = true;
    },
    itemSaved: function () {
      this.getList();
    },
    deleteParams: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.tips.sel.del.record'));
        return;
      }
      let list = chks.map(p => p.id);
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.record'),
        onOk: async () => {
          this.$axios.post(`device/light/DeleteLampConfig`, { list: list }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name', params.row.name),
        onOk: async () => {
          this.$axios.post(`device/light/DeleteLampConfig`, { list: [params.row.id] }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    repairStationEnergy: function () {
      // this.$Modal.confirm({
      //   title: this.showLang('com.op.prompt'),
      //   content: this.showLang('com.tips.ok.site.energy'),
      //   onOk: async () => {
      //     let start = new Date(this.formRepair.start).format('yyyy-MM-dd');
      //     let end = new Date(this.formRepair.end).format('yyyy-MM-dd');
      //     this.$axios.post(`//${this.domains.trans}/third/test/RepairStationBeforeEnergy`, { groupId, stationId, start, end }).then(res => {
      //       if (res.code == 0) {
      //         this.$Message.info(this.showLang('com.data.count.record', res.data));
      //       }
      //     });
      //   }
      // });
    },
    getList: function () {
      // this.exportExcel.data = [];
      this.filter.groupId = this.groupTreeSelectedNode.id;
      this.$axios.post(`device/light/QueryNonPoleLampConfig`, this.filter).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.$set(this, 'total', res.data.count);
          setTimeout(() => {
            this.checkRowKeys();
          }, 100);
          this.dataRefresh++;
          // let muti = false;
          // for(let item of res.data.list){
          //   if(item.enable1 && item.enable2){
          //     muti = true;
          //     break;
          //   }
          // }
          // this.rowHeight = muti ? 60 : 48;
        }
      });
    },
    importLampNew: function () {
      this.item = {};
      this.showImportModalNew = true;
    },
    importLampOld: function () {
      this.$Modal.info({
        title: this.showLang('com.tips.function'),
        content: this.showLang('com.tips.function.con'),
      })
    },
  }
}
</script>
<style scoped>
.config-device-container {
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  /* padding: 10px; */
  background-color: #F6F6FA;
  border-radius: 8px;
}
.config-tree-area{
  /* border: solid 1px #dcdee2; */
  width: 300px !important;
  flex: none;
  border-radius: 6px;
}
.device-data-area {
  margin-left: 10px;
  border: solid 1px #dcdee2;
  width: 300px;
  flex: auto;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
    background: #fff;
}
.query-data-area {
  border-bottom: solid 1px #dcdee2;
  align-items: center;
  /* margin: 5px 0; */
  padding: 5px 0;
  flex: none;
  min-height: 45px;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  padding: 5px;
  /* padding-left: 10px; */
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
.split-row-area{
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
}
.split-row-item{
  /* border-bottom: solid 1px red; */
  height: 30px;
  flex: none;
  line-height: 30px;
  text-align: center;
}
.split-row-flag{
  border-bottom: solid 1px #dcdee2;
}
</style>